import * as React from 'react';
import { MastHead } from './';
import ContactWidget from './ContactWidged';

export default class AboutUs extends React.Component<{}, {}> {
  render() {
    return (
      <div className="about-us">
        <MastHead title="Unser Betrieb" />
        <section className="page-content">
          <ContactWidget />
          <h2>Unser Betrieb</h2>
          <p>Wir sind ein kleines Unternehmen mit Sitz in Luxemburg.</p>
          <p>Ob Neu-oder Altbau, unser ausgebildetes Schreiner-Team kümmert sich um Ihre individuellen Bedürfnisse.</p>
          <h4>Unsere Firma ist spezialisiert auf:</h4>
          <ul>
            <li>erneuern oder reparieren von Fenster, Haustüren, Wintergärten</li>
            <li>schwer zu betätigende Fenster / Türen wieder gangbar machen</li>
            <li>reparieren von schwer gangbaren Rollläden oder umbau auf Motor</li>
            <li>Reparaturen von Küchenmöbel, Schränken, Kleinmöbel</li>
            <li>Montage / Aufbau von Möbel</li>
            <li>sofortige Behebung oder Sicherung bei Einbruchschäden oder Sturmschäden</li>
            <li>Verkauf und Montage von Zimmertüren</li>
            <li>Verkauf und Montage von Parkettböden</li>
            <li>Planung, Verkauf und Montage von Holzterrassen</li>
            <li>Verkauf von maßangefertigten Insektenschutzgitter</li>
            <li>maßangefertigte Möbel</li>
          </ul>
          <p>Sie können sich sicher sein, genau zu bekommen was wir Ihnen versprechen.</p>
          <p>Wir bieten Ihnen moderne und hochwertige Produkte, die auf dem neusten Stand der Technik sind.</p>
          <p>In unserem Showroom in Remich zeigen wir im kleinen Stil, Zimmertüren und Wohnungseingangstüren in verschiedenen Preissegmenten.</p>
          <p>Unser Montageteam ist für Privatkunden sowohl als auch für Firmenkunden tätig.</p>
          <p>Um auf dem neusten Stand der Technik zu sein, wird unser Betrieb regelmäßig geschult.</p>
          <p>Wir sind ein zertifizierter Passiv Haus Handwerksbetrieb.</p>
          <p>Eine Beratung und Besichtigung kann hier jederzeit nach telefonischer Vereinbarung erfolgen.</p>
        </section>
      </div>
    );
  }
}
