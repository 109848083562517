import * as React from 'react';

export default class ContactWidget extends React.Component<{}, {}> {
  render() {
    return (
      <div className="contact-widget">
        <h2>Kontakt</h2>
        <p>Wir beantworten gerne Ihre Fragen.</p>
        <p><a href="/kontakt">Schreiben Sie uns oder rufen Sie uns an.</a></p>
      </div>
    );
  }
}
