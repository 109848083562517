import * as React from 'react';
import { Link, NavLink } from 'react-router-dom';
import TransitionGroup from 'react-addons-css-transition-group';

export const clearer: React.CSSProperties = {
  clear: 'both'
};

export class Header extends React.Component<{}, {}> {

  menuCheckbox: HTMLInputElement;

  constructor(props: {}) {
    super(props);
    this.handleNavigateMenuState = this.handleNavigateMenuState.bind(this);
  }

  handleNavigateMenuState() {
    if (this.menuCheckbox.checked) {
      this.menuCheckbox.checked = false;
    }
  }

  render() {
    return (
      <div className="header">
        <div className="header-content">
          <div className="logo">
            <Link className="nav-link" to="/"><img src={require('../img/logo-253.png')} alt="MHN-Logo" /></Link>
          </div>
          <div className="menu">
            <input ref={(element) => this.menuCheckbox = element as HTMLInputElement} id="menu-toggle" type="checkbox" className="menu-toggle" />
            <label className="menu-toggle-label" htmlFor="menu-toggle" />
            <div className="menu-underlay" />
            <nav className="nav">
              <ul>
                <li><NavLink onClick={this.handleNavigateMenuState} activeClassName="nav-link-active" exact={true} to="/">Home</NavLink></li>
                <li><NavLink onClick={this.handleNavigateMenuState} activeClassName="nav-link-active" to="/unser-betrieb">Unser Betrieb</NavLink></li>
                <li><NavLink onClick={this.handleNavigateMenuState} activeClassName="nav-link-active" to="/produkte">Produkte</NavLink></li>
                <li><NavLink onClick={this.handleNavigateMenuState} activeClassName="nav-link-active" to="/service">Service</NavLink></li>
                <li><NavLink onClick={this.handleNavigateMenuState} activeClassName="nav-link-active" to="/kontakt">Kontakt</NavLink></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

interface IMastHeadProps {
  title: string;
  showMap?: boolean;
}
export class MastHead extends React.Component<IMastHeadProps, {}> {

  render() {
    return (
      <div className="masthead">

        <TransitionGroup
          className={(this.props.showMap) ? 'hidden' : 'masthead-title'}
          component="div"
          transitionName="title-transition"
          transitionAppear={true}
          transitionAppearTimeout={150}
          transitionEnter={false}
          transitionLeave={false}
        >
          <div className="header-bottom-line">&nbsp;</div>
            <div className="headline-transform">
              <h1>{this.props.title}</h1>
            </div>

          <div className="header-top-line">&nbsp;</div>
        </TransitionGroup>
        <div className="masthead-graphic" role="img">
          <div id="masthead-image">
            <div id="map" className={(this.props.showMap) ? '' : 'hidden'}>
              <iframe
                title="MHN Standort"
                width="600"
                height="450"
                style={{ border: 0}}
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCEfRTxz9h0PG7Hg_84uGhpSShDCT7aYYw&q=MHN Montage-Handwerk-Nalbach S.à.r.l, 47, Rue de Macher, L-5550 Remich"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class Footer extends React.Component<{}, {}> {
  render() {
    return (
      <footer className="footer">
        <div className="footer-content" role="presentation">
          <p>&copy; 2016 MHN <span className="footer-expanded">Montage Handwerk Nalbach</span> s.a.r.l.</p>
          <p><NavLink activeClassName="nav-link-active" to="impressum">Impressum</NavLink></p>
          <div className="footer-lobo">
            <a href="http://lobo-tueren.de/" target="_blank" rel="noopener noreferrer"><img src={require('../img/lobo-h40.png')} height={40} alt="Logo Lobo Tueren" /></a>
          </div>
        </div>
        <svg width="100%" height="100%" preserveAspectRatio="none" viewBox="0 0 1224 302">
          <polygon x="0" y="0" fill="#F0F0F0" points="0 20 1224 0 1224 302 0 302" />
          <polygon x="0" y="0" fill="#444444" points="0 226 1224 194 1224 302 0 302" />
        </svg>
      </footer>
    );
  }
}
