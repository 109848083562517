import * as React from 'react';
import { MastHead } from './';
import ContactWidget from './ContactWidged';

export default class Service extends React.Component<{}, {}> {
  render() {
    return (
      <div className="service">
        <MastHead title="Service" />
        <section className="page-content">
          <ContactWidget />
          <h2>Service</h2>
          <p>Neben der Optik und der Funktion Ihrer Baufertigelemente, sollte auch auf die professionelle Montage geachtet werden. Die meisten Fehler werden durch die falsche Montage bzw. nicht ausreichend geschulte Monteure verursacht.</p>
          <p>Anhand von Schulungen bei der Handwerkskammer oder bei Montagematerialzulieferer, werden wir regelmäßig auf den neusten Stand der Technik gebracht.</p>
          <p>Durch Fortbildungen sind wir ein Zertifizierter Passiv Haus Handwerksbetrieb.</p>
          <p>Wir unterstützen unsere Kunden auf Wunsch bei der Planung und der fachgerechten Montage von Fenster, Türen, Wintergärten, Überdachungen.</p>
          <h4>Auf folgende Dienstleistungen haben wir uns spezialisiert:</h4>
          <ul>
            <li>erneuern oder reparieren von Fenster, Haustüren, Wintergärten</li>
            <li>schwer zu betätigende Fenster / Türen wieder gangbar machen</li>
            <li>reparieren von schwer gangbaren Rollläden oder umbau auf Motor</li>
            <li>Reparaturen von Küchenmöbel, Schränken, Kleinmöbel</li>
            <li>Montage / Aufbau von Möbel</li>
            <li>sofortige Behebung oder Sicherung bei Einbruchschäden oder Sturmschäden</li>
            <li>Verkauf und Montage von Zimmertüren</li>
            <li>Verkauf und Montage von Parkettböden</li>
            <li>Planung, Verkauf und Montage von Holzterrassen</li>
            <li>Verkauf von maßangefertigten Insektenschutzgitter</li>
            <li>maßangefertigte Möbel</li>
          </ul>
        </section>
      </div>
    );
  }
}
