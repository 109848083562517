import * as React from 'react';
import { Link } from 'react-router-dom';
import { MastHead, clearer } from './';

export default class Home extends React.Component<{}, {}> {
  render() {
    return (
      <div className="home">
        <MastHead title="Home" />
        <section className="page-content">
          <h2>Willkommen bei MHN Montage Handwerk Nalbach s.a.r.l</h2>
          <div className="passiv-haus right">
            <a href="http://www.cdm.lu/formation-continue/energie-fir-d-zukunft-plus" target="_blank" rel="noopener noreferrer">
              <img src={require('../img/PassivHaus.jpg')} width="250" alt="Logo PassivHaus" />
            </a>
          </div>
          <p>Ihr Spezialist für Fenster, Zimmertüren sowie Wintergärten.</p>
          <p><strong>Wir sind ein durch das Chambre des Metiers Zertifizierter Passiv Haus Handwerksbetrieb!</strong></p>

          <h3 style={clearer}>Weitere Infos rund um unseren Betrieb</h3>
          <div className="grid row">
            <Link className="grid-box col col-3" to="/unser-betrieb">
              <div className="grid-label">Unser Betrieb</div>
              <img src={require('../img/carousel-about-us.jpg')} alt="Ueber uns" />
            </Link>

            <Link className="grid-box col col-3" to="/produkte">
              <div className="grid-label">Unsere Produkte</div>
              <img src={require('../img/carousel-products.jpg')} alt="Unsere Produkte" />
            </Link>

            <Link className="grid-box col col-3" to="/service">
              <div className="grid-label">Dienstleistungen</div>
              <img src={require('../img/carousel-service.jpg')} alt="Dienstleistungen" />
            </Link>
          </div>

          <br />
        </section>
      </div>
    );
  }
}

