import * as React from 'react';
import { MastHead } from './';

export default class Contact extends React.Component<{}, {}> {
  render() {
    return (
      <div className="contact">
        <MastHead title="Kontakt" showMap={true} />
        <section className="page-content">
          <h2>So erreichen Sie uns</h2>
          <p>
            MHN Montage-Handwerk-Nalbach S.à.r.l<br />
            47, Rue de Macher<br />
            L-5550 Remich, Luxemburg<br />
            Telefon: <a href="tel:+35227075427">+352 27075427</a><br />
            Mobil: <a href="tel:+352691587205">+352 691 587 205</a>
          </p>
          <p>
            E-Mail: <a href="mailto:info@mhn-sarl.eu">info@mhn-sarl.eu</a><br />
            Internet: <a href="http://www.mhn-sarl.eu">www.mhn-sarl.eu</a>
          </p>

          <ContactForm />

        </section>
      </div>
    );
  }
}

interface IContactFormState {
  name: string;
  email: string;
  phone: string;
  street: string;
  city: string;
  subject: string[];
  message: string;
  result: JSX.Element | null;
}

interface IContatFormProps {}

const options = [
  'erneuern oder reparieren von Fenster, Haustüren, Wintergärten',
  'schwer zu betätigende Fenster / Türen wieder gangbar machen',
  'reparieren von schwer gangbaren Rollläden oder umbau auf Motor',
  'Reparaturen von Küchenmöbel, Schränken, Kleinmöbel',
  'Montage / Aufbau von Möbel',
  'sofortige Behebung oder Sicherung bei Einbruchschäden oder Sturmschäden',
  'Verkauf und Montage von Zimmertüren',
  'Verkauf und Montage von Parkettböden',
  'Planung, Verkauf und Montage von Holzterrassen',
  'Verkauf von maßangefertigten Insektenschutzgitter',
  'maßangefertigte Möbel',
  'sonstiges'
];

class ContactForm extends React.Component<IContatFormProps, IContactFormState> {

  private formKey = 'RvCv4BeB';

  constructor(props: IContatFormProps, state: IContactFormState) {
    super(props);

    this.state = {
      name: '',
      email: '',
      phone: '',
      street: '',
      city: '',
      subject: [],
      message: '',
      result: null
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleStreetChange = this.handleStreetChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleSendMessageClick = this.handleSendMessageClick.bind(this);
  }

  handleNameChange(event: React.SyntheticEvent<HTMLInputElement>) {
    this.setState({ name: event.currentTarget.value });
  }

  handleEmailChange(event: React.SyntheticEvent<HTMLInputElement>) {
    this.setState({ email: event.currentTarget.value });
  }

  handlePhoneChange(event: React.SyntheticEvent<HTMLInputElement>) {
    this.setState({ phone: event.currentTarget.value });
  }

  handleStreetChange(event: React.SyntheticEvent<HTMLInputElement>) {
    this.setState({ street: event.currentTarget.value });
  }

  handleCityChange(event: React.SyntheticEvent<HTMLInputElement>) {
    this.setState({ city: event.currentTarget.value });
  }

  handleSubjectChange(event: React.SyntheticEvent<HTMLInputElement>) {
    const checked = event.currentTarget.checked;
    const entry = options[parseInt(event.currentTarget.id, 10)];

    if (checked) {
      this.state.subject.push(entry);
      this.setState({
        subject: this.state.subject
      });
    } else {
      const newSubject = this.state.subject.filter(el => el !== entry);
      this.setState({
        subject: newSubject
      });
    }
  }

  handleMessageChange(event: React.SyntheticEvent<HTMLTextAreaElement>) {
    this.setState({ message: event.currentTarget.value });
  }

  async handleSendMessageClick() {
    const data = new FormData();
    data.append('name', this.state.name);
    data.append('email', this.state.email);
    data.append('phone', this.state.phone);
    data.append('street', this.state.street);
    data.append('city', this.state.city);
    data.append('comment', `${this.state.subject.join(',\n')}\n\n${this.state.message}`);
    data.append('key', this.formKey);

    const response = await window.fetch('/send.php' , {
      method: 'POST',
      body: data
    });

    if (response.ok) {
      this.setState({
        name: '',
        email: '',
        phone: '',
        street: '',
        city: '',
        subject: [],
        message: '',
        result: <div className="success">Ihre Nachricht wurde versendet.</div>
      });
    } else {
      this.setState({
        name: '',
        email: '',
        phone: '',
        street: '',
        city: '',
        subject: [],
        message: '',
        result: <div className="error">Es ist ein Fehler beim verschicken Ihrer Nachricht aufgetreten. Bitte kontaktiern Sie uns telefonisch.</div>
      });
    }
  }

  render() {
    const { name, email, phone, street, city, message, result } = this.state;

    return (
      <div className="contact-form">
        <h2>Hinterlassen Sie uns eine Nachricht</h2>

        <div className="input-row">
          <label htmlFor="name">Name</label>
          <input type="text" id="name" value={name} onChange={this.handleNameChange} />
        </div>

        <div className="input-row">
          <label htmlFor="email">E-Mail-Adresse</label>
          <input type="text" id="email" value={email} onChange={this.handleEmailChange} />
        </div>

        <div className="input-row">
          <label htmlFor="phone">Telefonnummer</label>
          <input type="text" id="phone" value={phone} onChange={this.handlePhoneChange} />
        </div>

        <div className="input-row">
          <label htmlFor="street">Straße</label>
          <input type="text" id="street" value={street} onChange={this.handleStreetChange} />
        </div>

        <div className="input-row">
          <label htmlFor="city">Ort</label>
          <input type="text" id="city" value={city} onChange={this.handleCityChange} />
        </div>

        <div className="input-row">
          <label htmlFor="subject">Betreff</label>
          <div className="fakeinput">
            {options.map((value, index) => (
              <div key={index}>
                <input type="checkbox" id={`${index}`} key={index} value={value} onClick={this.handleSubjectChange} />
                <label style={{ width: 'auto', fontWeight: 'normal' }} htmlFor={`${index}`}>{value}</label>
              </div>
            ))}
          </div>
        </div>

        <div className="input-row">
          <label htmlFor="message">Nachricht</label>
          <textarea id="message" rows={10} onChange={this.handleMessageChange} value={message} />
        </div>

        <div className="input-row">
          <label />
          <button onClick={this.handleSendMessageClick}>Nachricht senden</button>
        </div>

        {result && (
          <div className="input-row">
            <label />
            {result}
          </div>
        )}

      </div>
    );
  }
}
