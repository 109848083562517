import * as React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect
} from 'react-router-dom';
import TransitionGroup from 'react-addons-css-transition-group';
import { Header, Footer } from './pages';
import './App.css';

import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Products from './pages/Products';
import Service from './pages/Service';
import Contact from './pages/Contact';
import Impress from './pages/Impress';

class RouteTransition extends React.Component<{}, {}> {
  render() {
    return (
        <TransitionGroup
          component="div"
          transitionName="route-transition"
          transitionAppear={true}
          transitionAppearTimeout={150}
          transitionEnter={false}
          transitionLeave={false}
        >
          {this.props.children}
        </TransitionGroup>
    );
  }
}

export default class App extends React.Component<{}, {}> {
  render() {
    return (
      <Router>
        <div>
          <Header />
          <Route path="/" exact={true} component={() => <Redirect to="/home" />}/>
          <Route path="/home" component={() => <RouteTransition><Home /></RouteTransition>}/>
          <Route path="/unser-betrieb" component={() => <RouteTransition><AboutUs /></RouteTransition>}/>
          <Route path="/produkte" component={() => <RouteTransition><Products /></RouteTransition>}/>
          <Route path="/service" component={() => <RouteTransition><Service /></RouteTransition>}/>
          <Route path="/kontakt" component={() => <RouteTransition><Contact /></RouteTransition>}/>
          <Route path="/impressum" component={() => <RouteTransition><Impress /></RouteTransition>}/>
          <Footer />
        </div>
      </Router>
    );
  }
}
