import * as React from 'react';
import { MastHead } from './';
import ContactWidget from './ContactWidged';

const ImageZoom = require('react-medium-image-zoom').default;

const images = {
  color: [{
    src: require('../img/cpl_colour/CPL Weisslack - RK60 - Vido Edelstahl RR - FZU.jpg'),
    alt: 'Weißlack'
  }, {
    src: require('../img/cpl_colour/CPL Weisslack 9016 RK - RK60 - Vido Edelstahl RR - FZU.jpg'),
    alt: 'Weißlack 9016'
  }, {
    src: require('../img/cpl_colour/CPL Uni Weiss RK - RK60 - Vido Edelstahl RR - FZU.jpg'),
    alt: 'Uni weiß'
  }, {
    src: require('../img/cpl_colour/CPL Uni grau RK - RK60 - Vido Edelstahl RR - FZU.jpg'),
    alt: 'Uni grau'
  }, {
    src: require('../img/cpl_colour/CPL Cremeweiss DK - DK60 - Vido Edelstahl RR - FZU.jpg'),
    alt: 'Cremeweiß'
  }, {
    src: require('../img/cpl_colour/CPL Softbeige DK - DK60 - Vido Edelstahl RR - FZU.jpg'),
    alt: 'Softbeige'
  }, {
    src: require('../img/cpl_colour/CPL Graubeige DK - DK60 - Vido Edelstahl RR - FZU.jpg'),
    alt: 'Graubeige'
  }, {
    src: require('../img/cpl_colour/CPL Anthrazitgrau DK - DK60 - Vido Edelstahl RR - FZU.jpg'),
    alt: 'Anthrazitgrau'
  }],
  holz: [{
    src: require('../img/cpl_holz/CPL Esche weiss deckend RK - RK60 - Vido Edelstahl RR - FZU.jpg'),
    alt: 'Esche weiß'
  }, {
    src: require('../img/cpl_holz/CPL Ahorn RK - RK60 - Vido Edelstahl RR - FZU.jpg'),
    alt: 'Ahorn'
  }, {
    src: require('../img/cpl_holz/CPL Buche RK - RK60 - Vido Edelstahl RR - FZU.jpg'),
    alt: 'Buche'
  }, {
    src: require('../img/cpl_holz/CPL Akazie RK - RK60 - Vido Edelstahl RR - FZU.jpg'),
    alt: 'Akazie'
  }],
  struktura: [{
    src: require('../img/cpl_struktura/CPL Pinie Silvergrey RK - RK65 - Ilsa Edelstahl RR.jpg'),
    alt: 'Pinie Silvergrey'
  }, {
    src: require('../img/cpl_struktura/CPL Pinie Silvergrey quer RK - RK65 - Ilsa Edelstahl RR.jpg'),
    alt: 'Pinie Silvergrey quer'
  }, {
    src: require('../img/cpl_struktura/CPL Pinie Cream RK - RK65 - Ilsa Edelstahl RR.jpg'),
    alt: 'Pinie Cream'
  }, {
    src: require('../img/cpl_struktura/CPL Pinie Cream quer RK - RK65 - Ilsa Edelstahl RR.jpg'),
    alt: 'Pinie Cream quer'
  }],
  mixed: [{
    src: require('../img/cpl_mixed/Kiel 2G Weisslack RK - RK60 - Paros Edelstahl RE - FZU.jpg'),
    alt: 'formprofilTür Kiel 2G'
  }, {
    src: require('../img/cpl_mixed/Siena SF1 Weisslack EK - DK70 - Menorca Variocolor RR - FZU.jpg'),
    alt: 'konturTür Siena SF1'
  }, {
    src: require('../img/cpl_mixed/Verona VO3 Weisslack EK - DK70 - Paros Edelstahl RE - FZU.jpg'),
    alt: 'konturTür Verona V03'
  }, {
    src: require('../img/cpl_mixed/Verona VO5 Weisslack EK - DK70 - Paros Edelstahl RE - FZU.jpg'),
    alt: 'konturTür Modena M05'
  }],
  glas: [{
    src: require('../img/cpl_glas/GG Vista 74 - Zarge Alugrau RK60 - Classic Borkum Edelstahl - FZU.jpg'),
    alt: 'formprofilTür Kiel 2G'
  }, {
    src: require('../img/cpl_glas/GG Sand 36 - Zarge Alugrau RK60 - Classic Borkum Edelstahl - Vase - FZU.jpg'),
    alt: 'konturTür Siena SF1'
  }, {
    src: require('../img/cpl_glas/GG Sand18 - Style100 - GM eckig 70 geschl Alu - F.jpg'),
    alt: 'konturTür Verona V03'
  }]
};

interface IGalleryImage {
  src: string;
  alt: string;
}

interface IGalleryProps {
  title: string;
  images: IGalleryImage[];
  chunkSize?: number;
}
class Gallery extends React.Component<IGalleryProps, {}> {

  static defaultProps: Partial<IGalleryProps> = {
    chunkSize: 4
  };

  private chunks: IGalleryImage[][] = [];

  constructor(props: IGalleryProps) {
    super(props);

    const data = this.props.images.slice(0);
    while (data.length > 0) {
      this.chunks.push(data.splice(0, this.props.chunkSize));
    }
  }

  render() {
    const { title, chunkSize } = this.props;
    return (
      <div className="product-gallery">
        <h3>{title}</h3>

        {this.chunks.map((chunk, index) => (
          <div className="grid row" key={index}>
            {chunk.map((image) => (
              <div
                className={`grid-box col col-${chunkSize}`}
                key={image.src}
              >
                <ImageZoom
                  image={image}
                  zoomImage={image}
                  className="col product-image"
                />
                <div className="grid-label">{image.alt}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }
}

export default class Products extends React.Component<{}, {}> {
  render() {
    return (
      <div className="products">
        <MastHead title="Produkte" />
        <section className="page-content">
          <ContactWidget />
          <h2>Produkte</h2>
          <p>Wo andere nur einen Durchgang zwischen zwei Räumen sehen, sehen wir ein architektonisch anspruchsvolles Design Element. Zimmertüren sind heute mehr als nur ein Durchgangselement, es wird in der heutigen Architektur vermehrt als Gestaltungsobjekt verwendet. Sie können somit zu einer harmonischen Wohlfühlatmosphäre beitragen.</p>
          <p>Lassen Sie sich von unserer Vielfältigen Auswahl an Türmodellen inspirieren. Hierzu haben wir eine kleine aber feine Ausstellung in Remich, wo Sie sich nach Terminvereinbarung gerne Beraten lassen können. Leider können wir hier nur einen kleinen Teil der Modellvarianten zeigen da die Auswahl doch riesig ist.</p>
          <p>Bei uns bekommen sie alles aus einer Hand. Von der Planung über die Lieferung bis hin zur Montage.</p>

          <Gallery title="CPL Colour" images={images.color} />
          <Gallery title="CPL Holzdesign" images={images.holz} />
          <Gallery title="CPL struktura" images={images.struktura} />
          <Gallery title="kontur-/formprofilTür" images={images.mixed} />
          <Gallery title="Glastüren" images={images.glas} chunkSize={3} />

          <h3 style={{ marginTop: '30px'}}>Weitere Modelle</h3>
          <p><b>finden Sie bei unserem Zulieferer LOBO-TÜREN:</b></p>
          <p>
            <a href="http://www.lobo-tueren.de" target="_blank" rel="noopener noreferrer">
              <img style={{ maxWidth: '100%', width: '100%'}} src={require('../img/lobo-b600.jpg')} alt="Logo Lobo Tueren" />
            </a>
          </p>
        </section>
      </div>
    );
  }
}
